@import '../../styles/common/common.scss';

///////////////////
// Slide Section //
///////////////////

.root {
    position: relative;
    overflow: hidden;
    padding-top: calc(var(--component-unhook-height) - rem(55px));
    padding-bottom: var(--component-unhook-height);

    @include bp(xl) {
        padding-top: calc(var(--component-unhook-height));
    }

    .bg_img {
        position: absolute;
        z-index: -1;
        filter: var(--utmb-filter-bgimg) !important;
    }

    .content_container {
        position: relative;
        color: var(--utmb-color-white);

        .title_wrapper {
            margin-top: rem(55px);
            text-align: center;
            text-transform: uppercase;
            text-shadow: var(--utmb-text-shadows);

            .text_01 {
                margin-bottom: rem(20px);
                font-size: rem(32px);
                line-height: rem(32px);
            }

            .text_02 {
                font-family: var(--utmb-font-futura-bold);
                line-height: 1;
                font-size: rem(200px);
                transition: font-size 0.1s;
            }
        }

        .slider_container {
            min-height: rem(390px);
            width: 100%;
            color: var(--utmb-color-default);
            text-align: left;
            margin-left: 0;
            z-index: 99;
            overflow: visible;

            .slide_item_container {
                position: relative;
                flex: 0 0 100%;
                background-color: var(--utmb-color-white);
                margin-right: rem(8px);
                margin-left: rem(8px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &:first-child {
                    margin-left: rem(16px);
                }

                &:last-child {
                    margin-right: rem(16px);

                    @include bp(md) {
                        margin-right: 0;
                    }
                }

                @include bp(md) {
                    flex: 0 0 50%;
                    margin-right: rem(32px);
                }

                @include bp(xl) {
                    flex: 0 0 calc(100% / 3);
                }
            }

            .card_placeholder {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: var(--utmb-color-blue-light);

                .card_image {
                    position: relative;
                    overflow: hidden;
                    height: 40%;
                    width: 100%;
                }
            }
        }
    }
}

//////////
// Dots //
//////////

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .mob_pad;

    &.hide_all_bp {
        visibility: hidden;
    }

    &.hide_dots_md {
        @include bp(md) {
            visibility: hidden;
        }
    }

    @include bp(lg) {
        &.hide_dots_xl {
            @include bp(xl) {
                visibility: hidden;
            }
        }
    }
}
