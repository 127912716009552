@import '../../../styles/common/common.scss';

.playground_modal {
    [data-reach-dialog-content] {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: var(--utmb-color-white);
        padding: 2rem;
        outline: none;

        @include bp(xxs) {
            max-width: 100vw;
            width: 100vw;
            padding: rem(29px);
        }

        @include bp(md) {
            max-width: rem(600px);
            width: 100%;
            padding: rem(50px);
        }

        .button_close {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            background: none;
            border: none;
            padding: rem(10px);
            z-index: 1;
        }

        .title {
            text-transform: uppercase;
            text-align: left;
        }

        .icon {
            width: rem(44px);
            height: rem(44px);
        }

        .list {
            text-transform: uppercase;
            display: flex;
            align-items: center;

            .title {
                margin-left: rem(10px);
            }
        }
    }
}
