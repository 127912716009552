@import '../../styles/common/common.scss';

$images-brightness: 0.6 !default;
$event-name-max-height: 90px !default;

.event_card {
    height: 100%;
    background-color: var(--utmb-color-white);

    &.events_world_map {
        @include bp(max md) {
            .header {
                height: rem(200px);
            }
        }

        .event_name {
            min-height: auto;
            max-height: rem($event-name-max-height);
        }
    }

    .header {
        position: relative;
        width: 100%;
        height: rem(270px);

        &.is_compact {
            height: rem(205px);
        }

        .image_container {
            position: relative;
            width: 100%;
            height: 100%;
            filter: brightness($images-brightness);
        }

        .logo {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: var(--utmb-color-white);

            &_wrapper {
                position: relative;
                width: rem(140px);
                height: rem(140px);
            }
        }
    }

    .playgrounds {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: rem(12px);
        margin-top: rem(10px);
        height: rem(40px);
        text-align: center;

        &_button {
            border: none;
            border-bottom: rem(1px) solid var(--utmb-color-ws-primary);
            color: var(--utmb-color-ws-primary);
            background-color: transparent;
            cursor: pointer;
        }

        &_number {
            font-size: rem(18px);
            line-height: rem(28px);
            letter-spacing: rem(0.78px);
        }
    }

    .list {
        margin: rem(30px) 0 0;
        padding: 0;
        list-style: none;
        font-size: rem(18px);

        li + li {
            margin-top: rem(10px);
        }

        li {
            display: flex;
            align-items: center;
            // height: rem(25px);
        }

        .prefix {
            min-width: rem(48px);
            text-align: center;
            margin-right: rem(4px);
        }

        .categories_list_container {
            min-width: rem(310px);
        }

        .category_icon {
            position: relative;
            width: rem(60px);
            height: rem(20px);

            &:not(:last-child) {
                margin-right: rem(6px);
            }
        }
    }

    .placeName,
    .countryName {
        text-transform: capitalize;
    }

    .registration_status {
        display: inline-block;
        width: rem(8px);
        height: rem(8px);
        border-radius: 100%;

        &.open {
            background-color: var(--utmb-color-green);
        }

        &.close {
            background-color: var(--utmb-color-red);
        }
    }

    .back_button {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: rem(50px);
        height: rem(50px);
        color: var(--utmb-color-ws-secondary);
        background-color: var(--utmb-color-ws-primary);
        border: 0;
        cursor: pointer;

        &:hover {
            svg {
                transform: rotate(-90deg) translateY(rem(-5px)) !important;
            }
        }

        svg {
            width: rem(24px);
            height: rem(24px);
            transition: transform 0.3s;
        }
    }

    .content {
        padding: 0 rem(10px);

        @include bp(md) {
            padding: 0 rem(40px);
        }
    }

    .event_name {
        margin-top: rem(32px);
        // line-height: rem(30px);
        text-align: center;
        text-transform: uppercase;
        min-height: rem($event-name-max-height);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: var(--utmb-color-ws-primary);
    }

    .eventTypeOnWS {
        text-align: center;
    }

    .button {
        display: block;
        margin: rem(30px) 0;
        text-decoration: none;
    }
}
