@import '../../styles/common/common.scss';

.flag {
    min-width: rem(28px);
    min-height: rem(21px);
    border-radius: rem(3px);
    box-shadow: 0 0 rem(10px) var(--utmb-color-border-gray);

    &.hide_shadow {
        box-shadow: none;
    }
    &.fi-tw {
        background-image: url(/svg/flags/tw.svg);
    }
    &.fi-an {
        background-image: url(/svg/flags/an.svg);
    }
}
